<template>
  <div>
    <transition name="fade">
      <CCard v-if="show" color="">
        <CCardHeader>
          Add Class
          <div class="card-header-actions">
            <CLink
              class="card-header-action btn-minimize"
              @click="isCollapsed = !isCollapsed"
            >
              <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
            </CLink>
            <CLink
              href="#"
              class="card-header-action btn-close"
              v-on:click="show = false"
            >
              <CIcon name="cil-x" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="isCollapsed" :duration="400">
          <CCardBody>
            <form>
              <div class="form-group row">
                <label
                  for="classFormControlSelect"
                  class="col-sm-4 col-form-label"
                  >Class</label
                >
                <div class="col-sm-8">
                 <input type ="text" name="school" class="form-control" placeholder="Class"  v-model="form.class_id">
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-8 offset-sm-4">
                   <CButton color="primary" size="md" class="border-0" @click="save_data1">
                    Submit
                  </CButton>&nbsp;&nbsp;
                  <CButton color="light" size="md" class="border-0" @click="reset">
                    Reset
                  </CButton>&nbsp;&nbsp;
                </div>
              </div>
            </form>
          </CCardBody>
        </CCollapse>
      </CCard>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
import apis from '@/apis';
import $ from "jquery";
import Swal from 'sweetalert2'; 
const base_url=process.env.VUE_APP_BASE_URL;


export default {
  name: "AddTeacher",
  components: {},
  data: function() {
    return {
    form: {
       class_id:""
      },
      show: true,
      isCollapsed: true,

    };
  },
  methods:{
    reset(){
      this.form.class_id = "";
    },
    save_data(){
      alert("Here");
    },
    save_data1(){
      if(this.para == '' || this.para == undefined){
        var data = {
          'class_standard': parseInt(this.form.class_id)
        };
        console.log(data);
        var promise = apis.add_ClassMasters(data)
        .then( response => {
          Swal.fire({
              width:300,
              title: 'Class Added Sucessfully !!',
              Text: 'Class Added Sucessfully !!'
          });
          this.reset();
        }).catch(error => {
          Swal.fire({
              width:200,
              title: 'Something Went Wrong !!',
              Text: 'Something Went Wrong !!'
          });
        });
      }else{
          // edit API will be call
      //      var data = {
      //        'school_id': this.id,
      //         'school_name': this.form.schoolName,
      //         'address': this.form.schoolAddress,
      //         'contact_no': this.form.contact,
      //         'contact_person': this.form.contactPerson,
      //         'district_id': '00000000-0000-0000-0000-000000000000'
      //     };
      //     console.log(data);
      //     axios.put(base_url + 'api/Schools/'+this.id,data).then( response => {
      //       this.isLoading = false;
            
      //       this.$alert("School Updated Sucessfully");
      //       this.$router.go(-1);

      //     }).catch(error => {

      //     });
        }
    },
  },
  created(){
  }
};
</script>
